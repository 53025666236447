<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div class="wrap" v-if="apiLoaded">
        <router-link to="/" class="go-back">
          <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M7.41 10.58L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.58Z"
                fill="#0033A0"
            />
          </svg>
          на интерактивную карту
        </router-link>

        <router-link :to="'/region/' + czn.region.code" class="go-back">
          <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M7.41 10.58L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.58Z"
                fill="#0033A0"
            />
          </svg>
          На карточку региона
        </router-link>

        <h1>
          <div>{{ czn.name }}</div>
          <div>
            <ReferenceMenu
                v-if="$authorized || czn != null && czn.reference != null"
                :czn-id="cznId"
                :modified-date="czn != null && czn.reference != null ? czn.reference.createdDateTime : null"
                :stored-file-id="czn != null && czn.reference != null ? czn.reference.id : null"
                :reference-file-name="czn != null && czn.reference != null ? czn.reference.name : null"
            ></ReferenceMenu>

            <v-dialog v-model="dialog" persistent max-width="600px" v-if="$authorized">
              <template v-slot:activator="{ on, attrs }">
                <a href="#" v-bind="attrs" v-on="on">
                  <v-btn>
                    <v-icon left dark>mdi-pencil</v-icon>
                    Редактировать
                  </v-btn>
                </a>
              </template>
              <CznEditDialog
                  @close="dialog = false"
                  :cznDataProp="czn"
                  :region="czn.region"
              />
            </v-dialog>

            <!--            <v-menu offset-y>-->
            <!--              <template v-slot:activator="{ on, attrs }">-->
            <!--                <v-btn class="round-btn" v-bind="attrs" v-on="on">-->
            <!--                  <svg-->
            <!--                    width="18"-->
            <!--                    height="18"-->
            <!--                    viewBox="0 0 18 18"-->
            <!--                    fill="none"-->
            <!--                    xmlns="http://www.w3.org/2000/svg"-->
            <!--                  >-->
            <!--                    <path-->
            <!--                      d="M5 14V12H13V14H5ZM13 7L9 11L5 7H7.5V4H10.5V7H13ZM2 0H16C17.11 0 18 0.9 18 2V16C18 17.11 17.11 18 16 18H2C0.9 18 0 17.11 0 16V2C0 0.9 0.9 0 2 0ZM2 2V16H16V2H2Z"-->
            <!--                      fill="#0033A0"-->
            <!--                    />-->
            <!--                  </svg>-->
            <!--                </v-btn>-->
            <!--              </template>-->
            <!--              <v-list>-->
            <!--                <v-list-item>-->
            <!--                  <v-list-item-title>Скачать PDF</v-list-item-title>-->
            <!--                </v-list-item>-->
            <!--                <v-list-item>-->
            <!--                  <v-list-item-title>Скачать XLS</v-list-item-title>-->
            <!--                </v-list-item>-->
            <!--              </v-list>-->
            <!--            </v-menu>-->
          </div>
        </h1>

        <div class="badges">
          <div>
            Участие в Федеральном Проекте
            <span>{{
                czn.fedProjectYear ? czn.fedProjectYear : "&mdash;"
              }}</span>
          </div>
          <div>
            Площадь помещения <span>{{ czn.area }} м<sup>2</sup></span>
          </div>
          <div>
            Дата открытия <span>{{ czn.openDate | dateFormat }}</span>
          </div>
        </div>

        <div class="table-wrap">
          <table class="info-table top-table">
            <tbody>
            <tr>
              <td>Субъект РФ</td>
              <td>{{ czn.region.name }}</td>
            </tr>
            <tr>
              <td>Адрес</td>
              <td>{{ czn.address }}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <h2>Ответственный от ЦЗН за ФП</h2>

        <div class="person">
          <div><img src="~@/assets/person.png" alt=""/></div>
          <div>
            <p>{{ czn.chiefFio }}</p>
            <span>{{ czn.chiefPost }}</span>
            <div>
              <a :href="'tel:' + czn.chiefPhone">{{ czn.chiefPhone }}</a> |
              <a :href="'mailto:' + czn.chiefEmail">{{ czn.chiefEmail }}</a>
            </div>
          </div>
        </div>

        <h2>
          <div></div>
        </h2>

        <div style="margin-bottom: 10px" v-if="$authorized">
          <a href="#" @click.prevent="$refs['finput'].click()">
            <v-btn>Добавить фото</v-btn>
          </a>
          <input
              type="file"
              ref="finput"
              style="display: none"
              @change="uploadPhoto($event)"
          />
        </div>
        <div>
          <VueSlickCarousel v-bind="settings" v-if="czn.photos.length > 0">
            <div
                v-for="(p, pIndex) in czn.photos"
                :key="'photokf' + pIndex"
                style="position: relative"
            >
              <img :src="photos[pIndex]" alt="" class="photo"/>
              <v-btn class="round-btn delbtn" @click="deletePhoto(p.id)" v-if="$authorized">
                <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M9.66634 1.27325L8.72634 0.333252L4.99967 4.05992L1.27301 0.333252L0.333008 1.27325L4.05967 4.99992L0.333008 8.72659L1.27301 9.66659L4.99967 5.93992L8.72634 9.66659L9.66634 8.72659L5.93967 4.99992L9.66634 1.27325Z"
                      fill="#0033A0"
                  />
                </svg>
              </v-btn>
            </div>
          </VueSlickCarousel>
        </div>

        <h2>
          <div>Информация</div>
          <!--          <v-text-field-->
          <!--            placeholder="Введите название показателя"-->
          <!--            value=""-->
          <!--            filled-->
          <!--            dense-->
          <!--            rounded-->
          <!--          ></v-text-field>-->
        </h2>

        <div class="table-wrap">
          <table class="info-table">
            <tbody>
            <tr
                v-for="(sit, sitIndex) in czn.lsituations"
                :key="sitIndex + 'igigf'"
            >
              <td v-if="sitIndex === 0" :rowspan="czn.lsituations.length">
                Перечень ЖС и год внедрения
              </td>
              <td>{{ sit.name }}</td>
              <td>{{ sit.year }}</td>
            </tr>

            <tr
                v-for="(sit, sitIndex) in czn.bsituations"
                :key="sitIndex + 'asdfrre'"
            >
              <td v-if="sitIndex === 0" :rowspan="czn.bsituations.length">
                Перечень БС и год внедрения
              </td>
              <td>{{ sit.name }}</td>
              <td>{{ sit.year }}</td>
            </tr>

            <tr
                v-for="(sv, svIndex) in czn.services"
                :key="svIndex + 'sosdfk'"
            >
              <td v-if="svIndex === 0" :rowspan="czn.services.length">
                Перечень новых сервисов для граждан и работодателей и год
                внедрения
              </td>
              <td>{{ sv.name }}</td>
              <td>{{ sv.year }}</td>
            </tr>

            <tr
                v-for="(sv, svIndex) in czn.aprobs"
                :key="svIndex + 'aprobfifd'"
            >
              <td v-if="svIndex === 0" :rowspan="czn.aprobs.length">
                Участие в апробациях
              </td>
              <td colspan="2">{{ sv }}</td>
            </tr>

            <tr
                v-for="(sv, svIndex) in czn.techs"
                :key="svIndex + 'techsdfsa'"
            >
              <td v-if="svIndex === 0" :rowspan="czn.techs.length">
                Внедрение технологии бережливого производства
              </td>
              <td colspan="2">{{ sv }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </transition>
  </div>
</template>


<script>
import api from "../modules/api";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import CznEditDialog from "../components/dialogs/CznEditDialog";
import ReferenceMenu from "@/components/elements/ReferenceMenu";

export default {
  name: "czn",
  components: {ReferenceMenu, VueSlickCarousel, CznEditDialog},
  data() {
    return {
      cznId: null,
      czn: {},
      photos: [],
      apiLoaded: false,
      dialog: false,
      isActive: false,
      settings: {
        arrows: true,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return "—";
      }
      let mdate = new Date(date);
      let options = {
        timeZone: "Europe/Moscow",
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      return mdate.toLocaleDateString("ru-RU", options);
    },
  },
  methods: {
    async loadCznData() {
      let req = await api.get("/map/getCznInfo", {
        id: this.cznId,
      });
      if (req.ok) {
        this.czn = req.payload;
        console.log(this.czn);
      } else alert("Ошибка загрузки данных");
    },

    async uploadPhoto(e) {
      let file = e.target.files[0];
      if (file != null) {
        await api.postFormData("/map/addPhoto", {
          cznId: this.cznId,
          multipartFile: file,
        });
      }
      location.reload()
    },

    async deletePhoto(id) {
      await api.postFormData("/map/deletePhoto", {
        cznId: this.cznId,
        id: id
      });
      location.reload()
    },

    async getPhoto(id) {
      let req = await api.get("/downloadFile", {fileId: id}, "blob");
      if (req.ok) {
        return URL.createObjectURL(req.payload);
      } else {
        alert("Ошибка загрузки данных");
      }
    },

    async updatePhotos() {
      this.photos = [];
      for (let p of this.czn.photos) {
        let pic = await this.getPhoto(p.id);
        this.photos.push(pic);
      }
    },
  },
  async beforeMount() {
    this.cznId = this.$route.params.id;
    await this.loadCznData();
    this.apiLoaded = true;
    this.updatePhotos();
  },
};
</script>

<style lang="scss">
@import "../styles/main.scss";
</style>

<style scoped lang="scss">
.photo {
  max-width: 400px;
  max-height: 200px;
}

.delbtn {
  // position: absolute;
  // top: 10px;
  // right: 10px;
  margin-left: 100px;
}
</style>
