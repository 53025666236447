<template>
  <v-menu bottom left offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn  v-bind="attrs" v-on="on">
        <v-icon left dark>mdi-text-box-outline</v-icon>
        Справка
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-if="$authorized" @click.prevent="$refs['finput'].click()" style="cursor: pointer; padding-left: 25px; color: rgb(0, 51, 160)">
        <v-list-item-title>Загрузить справку</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="modifiedDate != null" style="cursor: pointer; padding-left: 25px; color: rgb(0, 51, 160)" @click="downloadReference()">
        <v-list-item-content>
          <v-list-item-title>Скачать справку</v-list-item-title>
          <v-list-item-subtitle>Актуальность справки: {{modifiedDate | dateFormat}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <input
        type="file"
        ref="finput"
        style="display: none"
        @change="uploadFile($event)"
    />
  </v-menu>


</template>

<script>
import api from "@/modules/api";

export default {
  name: "ReferenceMenu",
  props: ["cznId", "storedFileId", "referenceFileName", "modifiedDate"],
  methods:{
    async uploadFile(e) {
      let file = e.target.files[0];
      if (file != null) {
        let req = await api.postFormData("/map/uploadReference", {
          cznId: this.cznId,
          multipartFile: file,
        });
        if(req.ok)
          location.reload()
        else
          alert('Что-то пошло не так')
        console.log(req);
      }
    },

    async downloadReference(){
      await api.download("/downloadFile", {fileId: this.storedFileId}, this.referenceFileName);
    }
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return "—";
      }
      let mdate = new Date(date);
      let options = {
        timeZone: "Europe/Moscow",
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      return mdate.toLocaleDateString("ru-RU", options);
    }
  }
}
</script>

<style scoped>

</style>
